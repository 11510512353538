@import url(https://fonts.googleapis.com/css?family=EB+Garamond);

* {
  box-sizing: border-box;;
}

body {
  margin: 0;
  padding: 40px;
  font: 16px/1.4 Garamond, 'EB Garamond', serif;
}

/* 342px is where the links start dropping to next line */
@media screen and (max-width: 342px) {
  body {
    padding: 20px;
  }
}

.content {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

header {
  margin-bottom: 40px;
}

h1 {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
}

.header__links {
  display: inline-block;
}

.header__link {
  color: black;
  margin-left: 10px;
}

.header__user-actions {
  display: inline-block;
}

.header__user-actions::before {
  content: '—';
  display: inline-block;
  margin-left: 10px;
}

.photo {
  margin-bottom: 40px;
}

.photo__image {
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
  background-color: whiteSmoke;
}

.photo__title {
  font-size: 14px;
  color: grey;
}

.photo--loading .photo__info {
  visibility: hidden;
}

/* Forms */

input,
select {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px;
  font-size: 16px;
  border: solid 2px silver;
}

input:focus,
.button:focus {
  outline-style: solid;
  outline-color: black;
  outline-width: 2px;
}

select {
  height: 38px;
}

button {
  cursor: pointer;
}

.button {
  padding: 8px 12px;
  font-size: 16px;
  background-color: silver;
  border: solid 2px silver;
  margin-right: 8px;
}

.button:last-child {
  margin-right: 0;
}

.button--link {
  font: bold 16px/1.4 Garamond, 'EB Garamond', serif;
  padding: 0;
  margin-left: 10px; /* match links */
  border: none;
  text-decoration: underline;
  background-color: transparent;
  color: black;
  outline: none;
}

/* Image Uploader */

.image-uploader {
  margin-top: 10px;
  margin-bottom: 40px;
}

.image-uploader__input {
  display: block;
  width: 100%;
  max-width: 400px;
}

.image-uploader__title-hint {
  display: block;
  position: relative;
  top: -4px;
  margin-bottom: 4px;
  color: grey;
}

